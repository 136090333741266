@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

body {
  font-family: "Almarai", sans-serif;
}

.slick-dots {
  position: relative !important;
  bottom: -10px !important;
}

.slick-dots li.slick-active button:before {
  color: #f59e0b !important;
  opacity: 100;
  font-size: large;
}

.slick-dots li button:before {
  color: #f59e0b !important;
  font-size: small;
  transition: all;
}

.slick-dots li button:before:hover {
  font-size: large;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide div {
  height: 100%;
}
